<template>
  <main>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.eirList')+' - '+$t('label.damageType')}}  </b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end py-2">
            <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click="toggleCreate"
              v-c-tooltip="{
                content: $t('label.damageType'),
                placement: 'top-end'
              }"
            >
              <CIcon name="cil-playlist-add"/>&nbsp;
              {{$t('label.nuevo')}}
            </CButton>
          </CCol>
        </CRow>
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedListTypeDamage"
          :fields="fields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="loading"
          sorter
        >
          <template #loading>  
            <loading/>
          </template>  
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item, index }">
            <td>
 
                <CButton
                  size="sm"
                  square
                  color="edit"
                  class="d-flex align-items-center"
                  @click="toggleEdit(item, index)"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.damageType'),
                    placement: 'top-end'
                  }"
                >
                  <CIcon name="pencil" />
                </CButton>
            
            </td>
          </template>
        </dataTableExtended>
      </CCardBody>
    </CCard>
    <type-damage-create
      :modal.sync="modalCreate"
      @set-type-damage-list="setTypeDamageListCreate"
    />
    <type-damage-update
      :modal.sync="modalUpdate"
      @set-type-damage-list="setTypeDamageListUpdate"
      :typeDamageSelect="typeDamageSelect"
    />
  </main>
</template>
<script>
import TypeDamageCreate from './modal-create-type-damage';
import TypeDamageUpdate from './modal-edit-type-damage';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';


function data() {
  return {
    items: [],
    modalCreate: false,
    modalUpdate: false,
    typeDamageSelect: {},
    loading: false,
  };
}

function fields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%; text-align:left', filter: false},
     { label: this.$t('label.damageType')+' '+'(ES)', key: 'TpDamageName',_style: 'width:27%;',_classes: 'text-uppercase text-center'},
     { label: this.$t('label.damageType')+' '+'(EN)', key: 'TpDamageNameEn',_style: 'width:27%;',_classes: 'text-uppercase text-center'},
     { label: this.$t('label.acronym'), key: 'TpDamageAbbrev',  _classes: 'text-uppercase text-center', _style: 'width:27%;', },
     { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase text-center', _style:'width:12%' },
     { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:12%' },
     { label: this.$t('label.status'), key: 'Status', _classes: 'text-center text-uppercase', _style:'width:12%' },
     { label: '', key: 'options', _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
  ];
}

function toggleEdit(item, index) {
  this.typeDamageSelect = Object.assign({}, item);
  this.modalUpdate = !this.modalUpdate;
}

function computedListTypeDamage() {
  return this.items.map((item) => {
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

function mountedTypeDamageList() {

  this.loading = true;
  this.$http
    .get('TpDamage-list')
    .then((response) => (this.items = response.data.data))
    .finally(() => {
      this.loading = false;
    });
}

function toggleCreate() {
  this.modalCreate = !this.modalCreate;
}

async function setTypeDamageListCreate(item) {
  this.items.push({
    ...item,
    Nro: this.items.length + 1,
  });
  //await this.mountedTypeDamageList()
}

function setTypeDamageListUpdate(itemNew) {
  const newList = this.items.map((item, index) => {
    if (item.TpDamageId === itemNew.TpDamageId) {
      return {
        ...itemNew,
        Nro: index + 1,
      };
    }
    return {
      ...item,
      Nro: index + 1,
    };
  });
  this.items = newList;
}

export default {
  name: 'tpdano-index',
  components: { TypeDamageCreate, TypeDamageUpdate },
  data,
  mixins: [GeneralMixin],
  methods: {
    toggleCreate,
    setTypeDamageListCreate,
    setTypeDamageListUpdate,
    toggleEdit,
  },
  mounted: mountedTypeDamageList,
  computed: {
    computedListTypeDamage,
    fields,
  },
};
</script>