var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.eirList')+' - '+_vm.$t('label.damageType'))+" ")])]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.damageType'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.damageType'),\n              placement: 'top-end'\n            }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleCreate}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nuevo'))+" ")],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedListTypeDamage,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loading,"sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item, index }){return [_c('td',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.damageType'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.damageType'),\n                  placement: 'top-end'\n                }"}],staticClass:"d-flex align-items-center",attrs:{"size":"sm","square":"","color":"edit"},on:{"click":function($event){return _vm.toggleEdit(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1),_c('type-damage-create',{attrs:{"modal":_vm.modalCreate},on:{"update:modal":function($event){_vm.modalCreate=$event},"set-type-damage-list":_vm.setTypeDamageListCreate}}),_c('type-damage-update',{attrs:{"modal":_vm.modalUpdate,"typeDamageSelect":_vm.typeDamageSelect},on:{"update:modal":function($event){_vm.modalUpdate=$event},"set-type-damage-list":_vm.setTypeDamageListUpdate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }